<template>
  <div>
    <v-row style="margin-top: 50px" v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>

    <div v-else>
      <v-form v-model="validArquivos" ref="formArquivo" lazy-validation v-if="contadorDefinido">
        <v-container>
          <v-widget title="Período dos Arquivos" :temCampoObrigatorio="true">
            <div slot="widget-content">
              <v-row dense>
                <v-col cols="12" sm="4">
                  <campo-data label="Data Inicial *" v-model="periodo.data_inicial"></campo-data>
                </v-col>
                <v-col cols="12" sm="4">
                  <campo-data label="Data Final *" v-model="periodo.data_final"></campo-data>
                </v-col>
              </v-row>
            </div>
            <template v-slot:widget-footer-action>
              <v-btn text @click="cancelar()">Cancelar</v-btn>
              <v-btn color="primary" :loading="carregando" :disabled="!podeEnviarEmail" @click="enviarArquivos()" data-cy="enviar">Enviar por E-mail</v-btn>
            </template>
          </v-widget>
        </v-container>
      </v-form>

      <v-form v-model="valid" ref="formContador" lazy-validation>
        <v-container grid-list-md>
          <v-widget title="Informações do Contador" :temCampoObrigatorio="true" lazy-validation>
            <div slot="widget-header-action">
              <v-btn small outlined fab icon v-if="contadorDefinido" :disabled="!valid" color="primary" @click="editarContador()" :loading="carregando" data-cy="editar"><v-icon>save</v-icon></v-btn>
              <v-btn small outlined fab icon v-else color="primary" :disabled="!valid" @click="salvarContador()" :loading="carregando" data-cy="salvar"><v-icon>save</v-icon></v-btn>
            </div>
            <div slot="widget-content">
              <v-row dense>
                <v-col cols="12" sm="3">
                  <v-text-field label="CPF *" v-model="contador.cpf" :rules="validarCpf" v-mask="'###.###.###-##'" required data-cy="cpf"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Nome *" v-model="contador.nome" :rules="validarNome" required data-cy="nome"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="CRC *" v-model="contador.crc" :rules="validarCRC" required data-cy="crc"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <campo-cep v-mask="'#####-###'" label="CEP *" buscarcep @resultado="setEndereco" v-model="contador.cep" append-icon="map"></campo-cep>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-text-field label="Logradouro" v-model="contador.logradouro" data-cy="logradouro"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field label="Número" v-model="contador.numero" data-cy="numero"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="Complemento" v-model="contador.complemento" data-cy="complemento"></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field label="Bairro" v-model="contador.bairro" data-cy="bairro"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-autocomplete label="Estado *" v-model="contador.estado" :items="constantes.ESTADOS_AUTOCOMPLETE" @change="filtrarCidadesEstado(contador.estado)"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete label="Cidade *" v-model="contador.codigo_cidade" @change="setCidade(contador.codigo_cidade)" :items="cidades" :rules="validarCidade" :disabled="cidades.length == 0 || $store.state.arquivo.carregando_cidade" :loading="$store.state.arquivo.carregando_cidade"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field label="País" v-model="contador.pais" data-cy="pais" readonly></v-text-field>
                </v-col>
                <v-col cols="12" sm="7">
                  <v-text-field label="E-mail *" v-model="contador.email" :rules="validarEmail" required data-cy="email"></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field label="Telefone" v-model="contador.telefone" v-mask="'(##)####-####'" data-cy="telefone"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-widget>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>

import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import constantes from '@/util/constantes';
import validar from '@/util/validador';

export default {

  data: () => ({
    valid: false,
    validArquivos: false,
    validarNome: [v => !!v || "Nome Obrigatório"],
    validarCRC: [v => !!v || "CRC Obrigatório"],
    validarCpf: [v => (v && validar.cpf(v) || 'CPF Inválido')],
    validarEmail: [v => (v && validar.email(v)) || "E-mail Inválido"],
    validarCidade: [v => !!v || "Cidade Obrigatória"],
    constantes: constantes,
    loading: false
  }),

  computed: {

    ...mapState('arquivo', {
      notas: 'notas',
      autorizadas: 'autorizadas',
      canceladas: 'canceladas',
      inutilizadas: 'inutilizadas',
      denegadas: 'denegadas',
      empresa: 'empresa',
      contador: 'contador',
      carregando: 'carregando',
      contadorDefinido: 'contadorDefinido',
      periodo: 'periodo'
    }),

    ...mapGetters('arquivo', {
      cidades: 'listaCidades'
    }),

    podeEnviarEmail(){

        let contador = this.$store.state.arquivo.contador;
        let periodo = this.$store.state.arquivo.periodo;
        if(contador._id && periodo.data_inicial && periodo.data_inicial != '' && periodo.data_final && periodo.data_final != ''){
            return true;
        }
        else{
            return false;
        }
    }
  },

  methods: {

    ...mapActions('arquivo', {
      carregarContador: 'carregarContador',
      filtrarCidadesEstado: 'filtrarCidadesEstado'
    }),

    ...mapMutations('arquivo', {
      setCidade: 'setCidade'
    }),

    async enviarArquivos(){

      if(this.$refs.formArquivo.validate()){
        try{
          await this.$store.dispatch('arquivo/enviarArquivos');
        }
        catch(erro){
          console.log(erro);
        }
      }
    },

    setEndereco(endereco) {
      this.$store.commit('arquivo/setEndereco', endereco);
      this.filtrarCidadesEstado(this.contador.estado)
    },

    async salvarContador(){

      if(this.$refs.formContador.validate()){
        try{
          await this.$store.dispatch('arquivo/salvarContador');
        }
        catch(erro){
          console.log(erro);
        }
      }
    },

    cancelar() {
      this.$router.push('/home');
    },

    editarContador(){

      if(this.$refs.formContador.validate()){
        this.$store.dispatch('arquivo/editarContador');
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('arquivo/setCidades', []);
    next();
  },

  async created(){

    try{

      this.loading = true;
      await this.carregarContador();

      let contador = this.$store.state.arquivo.contador;
      if(contador && contador._id && contador._id !== ''){
        await this.filtrarCidadesEstado(contador.estado);
      }
    }
    catch(erro){
      console.log(erro);
    }
    finally{
      this.loading = false;
    }
  }
}
</script>